@charset 'UTF-8';

/*
* !!! Only use this file if this is a DS or DSR engagement with MULTIPLE THEMES
*
* Replace 'myThemeName' below your theme name
*
* If you have multiple themes, just copy this file and change the name and variable below
* for each theme
*/

$themeName: 'Express Pros';

$borderRadius: 9px !default;
/*
* Here is where you override any default variables from 'Style Library/base/variables.scss'
* for this particular theme
* For convenience, here are the ones you might be most likely to modify per theme
	$colorBrandPrimary: ...;
	$colorBrandSecondary: ...;
	$colorBrandTertiary: ...;
	$colorText: ...; //color overall body copy
	$colorLinks: ...; //color basic links sitewide
	$colorLinksHover: ...; //color basic links hover color sitewide
	$fontDefault: ...;
	$fontHighlight: ...;
	$fontIcon: ...;
*/

/* MAIN.CSS
========================================================================== */

/*
 * SETTINGS imports
 *
 * These are overarching site variables.
 *
 * These are included in a certain order based on dependancy on each other. If you shuffle things
 * around bewaaaare
*/

@import '9-sites/ep/1-settings/colors';
@import '9-sites/ep/1-settings/buttons';
@import '1-settings/fontFamilies';
@import '1-settings/fontSize';
@import '1-settings/lineHeight';
@import '1-settings/spacingUnits';
@import '1-settings/forms';
@import '1-settings/filePaths';
@import '1-settings/maxWidth';
@import '1-settings/gutters';
@import '1-settings/mediaQueries';


/*
 * TOOLS imports
 *
 * These are mixins and functions for sitewide use.
*/

@import '2-tools/font-size';
@import '2-tools/keyframe';
@import '2-tools/media-queries';
@import '2-tools/mq-visibility';
@import '2-tools/transition';
@import '2-tools/truncate';
@import '2-tools/responsive-font';
@import '2-tools/flexbox';
@import '2-tools/visuallyHidden';
@import '2-tools/noPhatBottom';
@import '2-tools/clearfix';
@import '2-tools/contained';
@import '2-tools/guttered';
@import '2-tools/themify';
@import '2-tools/font-face';
@import '2-tools/contrast';
@import '9-sites/ep/2-tools/contrast-generator';

/*
 * GENERIC imports
 *
 * These are generic, overarching CSS rules.
*/

@import '3-generic/normalize';
@import '3-generic/vertical-rythm';
@import '3-generic/fonts';
@import '3-generic/selection';
@import '3-generic/print';

/*
 * ELEMENTS imports
 *
 * These are bare, unclassed HTML elements.
 * !!! No classes allowed in these partials
*/

@import '4-elements/html';
@import '4-elements/body';
@import '4-elements/link';
@import '4-elements/headings';
@import '4-elements/code';
@import '4-elements/img';
@import '4-elements/lists';
@import '4-elements/main';
@import '4-elements/figure';
@import '4-elements/hr';
@import '4-elements/quote';
@import '9-sites/ep/4-elements/aside';
@import '4-elements/scrollbar';

/*
 * OBJECTS imports
 *
 * These are class-based, non-cosmetic design elements.
*/

@import '5-objects/pageWrap';
@import '9-sites/ep/5-objects/section';
@import '5-objects/grid';
@import '5-objects/island';
@import '5-objects/sideToSide';
@import '5-objects/mediaBlock';

/*
 * COMPONENTS imports
 *
 * These are specifically styled UI elements and structures
 *
*/

@import '6-components/icons';
@import '9-sites/ep/6-components/button';
@import '6-components/alert';
@import '6-components/notification';
@import '9-sites/ep/6-components/navSecondary';
@import '6-components/form-error';
@import '6-components/toggleLink';
@import '6-components/badge';
@import '6-components/datepicker';
@import '6-components/bug';
@import '6-components/loader';
@import '6-components/tag';
@import '6-components/forms';
@import '6-components/popup';
@import '6-components/tabs';
@import '6-components/flyout';
@import '6-components/headerStyleguide';
@import '9-sites/ep/6-components/headerMain';
@import '9-sites/ep/6-components/footerMain';
@import '9-sites/ep/6-components/slickSlider';
@import '9-sites/ep/6-components/sliderMain';
@import '9-sites/ep/6-components/pagination';
@import '9-sites/ep/6-components/navMain';
@import '6-components/navMainMobile';
@import '6-components/lists';
@import '6-components/figure';
@import '6-components/hr';
@import '6-components/img';
@import '6-components/paragraph';
@import '6-components/aside';
@import '6-components/code';
@import '6-components/quote';
@import '6-components/table';
@import '6-components/separator';
//Header components
@import '9-sites/ep/6-components/navUtil';
@import '6-components/navCallout';
@import '6-components/navSignin';
@import '6-components/navUser';
@import '6-components/logoMain';
@import '9-sites/ep/6-components/searchToggle';
// @import '6-components/cartStatus';
@import '9-sites/ep/6-components/mobileDrawerToggle';
@import '9-sites/ep/6-components/locBlurb';
@import '6-components/locSearch';
//Marketier/CMS components
@import '6-components/trailingLink';
@import '6-components/infoList';
@import '6-components/truncateList';
@import '6-components/backLink';
@import '6-components/fakeSelect';
@import '6-components/pageMeta';
@import '6-components/accordion';
@import '9-sites/ep/6-components/banner';
@import '9-sites/ep/6-components/blogItem';
@import '6-components/comment';
@import '9-sites/ep/6-components/contentSlider';
@import '9-sites/ep/6-components/featureBlock';
@import '9-sites/ep/6-components/gallery';
@import '9-sites/ep/6-components/listContent';
@import '9-sites/ep/6-components/listImages';
@import '9-sites/ep/6-components/location';
@import '9-sites/ep/6-components/locationFinder';
@import '9-sites/ep/6-components/locationResults';
@import '6-components/drawer';
@import '6-components/filters';
@import '9-sites/ep/6-components/pageTitle';
@import '6-components/resultsTools';
@import '9-sites/ep/6-components/searchBox';
@import '6-components/searchItem';
@import '6-components/socialFeed';
@import '6-components/socialShare';
@import '9-sites/ep/6-components/spotlight';
@import '9-sites/ep/6-components/video';
@import '6-components/videoPopup';

//Commerce components
// @import '6-components/navOptions';
// @import '6-components/statusBar';
// @import '6-components/toggleCheck';
// @import '6-components/formNote';
@import '9-sites/ep/6-components/navHorizontal';
// @import '6-components/productTile';
// @import '6-components/productGallery';
// @import '6-components/productTools';
// @import '6-components/productRating';
// @import '6-components/cartItem';
// @import '6-components/swapBox';
// @import '6-components/infoAction';
// @import '6-components/rating';
@import '9-sites/ep/6-components/eventDetail';
// @import '6-components/profile';
// @import '6-components/signinRegister';
@import '6-components/backTopLink';
@import '6-components/listFiles';
// @import '6-components/socialBlock';
// @import '6-components/featureCompare';
@import '9-sites/ep/6-components/iconCards';

/*
 * PAGE imports
 *
 * These are to be used in the case where singular pages of the site deserve special treatment
 * and are meant to be used very sparingly.
 *
 * e.g. - A singular landing page needs a different background or font compared to the rest of the site.
*/
// @import '7-pages/styleguide';

/*
 * HELPERS imports
 *
 * These are standalone helper classes that override other rules by using !important declarations.
*/

@import '8-helpers/caps';
@import '9-sites/ep/8-helpers/colors';
@import '8-helpers/disabled';
@import '8-helpers/fills';
@import '8-helpers/floats';
@import '8-helpers/fontFamilies';
@import '8-helpers/fontSizes';
@import '8-helpers/margins';
@import '8-helpers/muted';
@import '8-helpers/overline';
@import '8-helpers/padding';
@import '8-helpers/showHide';
@import '8-helpers/strikeThrough';
@import '8-helpers/textAlignment';
@import '8-helpers/underline';
@import '8-helpers/visuallyHidden';
@import '8-helpers/clear';

/*
 * LOCATIONS Specific imports
 *
 * These are mixins and functions for Locations Templates
*/
@import '6-components/banner-locations';

@import '9-sites/ep/6-components/featureCard';
@import '9-sites/ep/6-components/reviews';
@import '9-sites/ep/6-components/testimonials';
@import '9-sites/ep/6-components/employerTools';
@import '9-sites/ep/6-components/jobSeekersEmployers';
// @import '9-sites/ep/6-components/crwidgets';
@import '9-sites/ep/6-components/newsFeedContainer';
@import '9-sites/ep/6-components/jobListings';
@import '9-sites/ep/8-helpers/gradients';

@import '8-helpers/expresspros';